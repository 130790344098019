import React from "react";
import Grid from "@material-ui/core/Grid";
import { Box, IconButton, Collapse } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Divider from "@material-ui/core/Divider";
import singaporeSmeBannerImg from "../images/v2/mobile-crm/background-img.png";
import tickImage from "../images/v2/whatsapp/tickIconImage.png";
import useWidth from "../hooks/useWidth";
import requestDemoBGImg from "../images/v2/mobile-crm/request-demo-bg.svg";
import SEO from "../components/SEO";
import whatsappBackgroundImg from "../images/v2/campaigns/whatsappBackgroundImg.png";
import yellowLine from "../images/v2/mobile-crm/yellow-line.png";
import { StaticImage } from "gatsby-plugin-image";
import firstImage from "../images/v2/whatsapp/FirstImageGreen.png";
import secondImage from "../images/v2/whatsapp/SecondImageBlue.png";
import { objectOf } from "prop-types";

const useStyles = makeStyles((theme) => ({
  marginAuto: {
    margin: "0 auto",
  },
  header: {
    boxShadow: "0 2px 4px 0 rgba(0,0,0,.29)",
    padding: ".65rem 0 .25rem",
  },
  backgroundWhite: {
    position: `relative`,
    backgroundColor: `#fff`,
  },

  cardImg: {
    width: "100%",
    height: "auto",
    transform: "scale(1.1)",
  },

  relativeContainer2: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
  },

  cardindexBackground: {
    boxShadow: "none",
    borderRadius: "12px",
    border: "solid 1px #0893af",
  },
  faqbackground: {
    position: `relative`,
    // background: `#fff url(${faqbackgroundImg}) no-repeat center`,
    backgroundSize: "contain",
    // margin: "79.5px 0 ",
    [theme.breakpoints.down("xs")]: {
      marginTop: "28px",
      marginBottom: "50px",
    },
  },
  whatsappBackground: {
    position: "relative",
    background: `#fff url(${whatsappBackgroundImg}) no-repeat center`,
    width: "100%",
    backgroundSize: "cover",
  },
  singaporeSmeBanner: {
    position: `relative`,
    background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.up(1600)]: {
      position: `relative`,
      background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
      backgroundSize: "cover",
      width: "100%",
    },
  },
  singaporeSmeBanner2: {
    position: `relative`,
    background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.up(1600)]: {
      position: `relative`,
      background: `#fff url(${singaporeSmeBannerImg}) no-repeat center`,
      backgroundSize: "cover",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: "100px",
    },
  },
  primaryText: {
    fontSize: "20px",
    fontWeight: "600",
    fontFamily: "Poppins",
    paddingBottom: "4px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
    },
  },
  secondaryText: {
    fontSize: "20px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
    },
  },

  listBackground: {
    position: `relative`,
    // background: `#fff url(${listBackgroundImage}) no-repeat center`,
    // backgroundSize: "cover",
    width: "100%",
  },
  singaporeSmeBannerRightImg: {
    position: `absolute`,
    backgroundSize: "contain",
    paddingTop: "50px",
    right: `-25%`,
    backgroundPosition: `right`,
    width: `100%`,
    height: `100%`,
    top: `25%`,
    [theme.breakpoints.down(1120)]: {
      display: `none`,
    },
    [theme.breakpoints.up(1300)]: {
      right: `-20%`,
    },
    [theme.breakpoints.up(1600)]: {
      right: `-15%`,
    },
    [theme.breakpoints.up(1900)]: {
      right: `0%`,
    },
  },
  singaporeSmeFromBox: {
    [theme.breakpoints.up("md")]: {
      paddingTop: "0",
    },
    [theme.breakpoints.up("xs")]: {
      paddingTop: "5rem",
    },
  },

  listItemTextes: {
    "& *": {
      fontSize: 23,
      color: "#2e3f4f",
      whiteSpace: "pre",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemTexts: {
    "& *": {
      fontSize: 20,
      color: "#2e3f4f",
      whiteSpace: "pre",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        whiteSpace: "pre",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
        whiteSpace: "normal",
      },
    },
  },
  listItemTextWhite: {
    "& *": {
      fontSize: 20,
      color: "#fff",
      whiteSpace: "noWrap",
      [theme.breakpoints.down("sm")]: {
        fontSize: 18,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: 15,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 18,
      },
    },
  },
  tickImages: {
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      padding: "0",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      padding: "0",
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 26,
    backgroundColor: "#15a323",
    borderRadius: "50%",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
  },
  singaporeSmeFrom: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 #b7b7b7",
    backgroundSize: "430px 522px",
    position: "relative",
    zIndex: "1",
    margin: "0 auto",
    width: "90%",
    marginLeft: "56px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
  },
  singaporeSmeFromHeader: {
    background: "#2e3f4f",
    borderRadius: "10px 10px 0 0",
    padding: ".6rem .25rem",
    letterSpacing: ".32px",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  integration: {
    whiteSpace: "nowrap",
  },

  img: {
    backgroundSize: "contain",
  },
  nopad: {
    paddingLeft: "0",
    paddingTop: "0",
  },
  nopads: {
    paddingBottom: "24px",
  },

  nopadtpbtm: {
    paddingTop: "0",
    paddingBottom: "0",
  },
  listPadding: {
    whiteSpace: "pre",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px",
    },
  },
  listFourpadding: {
    paddingLeft: "56px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  colorChange: {
    color: "#ff7a59",
    fontSize: "30px",
    fontWeight: "700",
    letterSpacing: ".4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },
  whitespaceNowrap: {
    whiteSpace: "nowrap",
  },
  pepperCloudCRMHelps: {
    fontSize: "40px",
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
      paddingTop: "32px",
      fontSize: "40px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  pepperCloudCRMHelpsText: {
    fontSize: "40px",
    [theme.breakpoints.up("md")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
      paddingTop: "4px",
      fontSize: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
      paddingTop: "4px",
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "100%",
      backgroundPosition: "center",
      paddingTop: "4px",
      fontSize: "22px",
    },
  },
  pepperCloudCRMHelpsContent: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#fff7e0",
    padding: "11px 0 20px 0",
  },
  pepperCloudCRMHelpsContentListBox: {
    padding: "0rem 1rem",
  },
  pepperCloudCRMHelpsContentList: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    padding: "0px",
    textAlign: "center",
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // textAlign: "left",
    },
  },
  footerPadding: {
    paddingTop: "60px",
    paddingBottom: "60px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      paddingBottom: "30px",
    },
  },
  pepperCloudCRMHelpsContentListItem: {
    flex: "1 1 calc(33.333% - 10px)",
    margin: "5px",
    [theme.breakpoints.down("sm")]: {
      flex: "1 1 100%",
      marginBottom: "10px",
    },
  },
  copyFooter: {
    color: "#cbcccd",
    textAlign: "end",
    paddingRight: "23px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },
  pepperCloudCRMHelpsContentListItemSpan: {
    color: "#e2ab10",
    fontSize: "61px",
    fontWeight: "600",
    lineHeight: "normal",
    fontFamily: "Poppins",
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  faqCard: {
    borderRadius: "20px",
    boxShadow: "1px 1px 10px 0 rgba(0, 0, 0, 0.16)",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  smeBannerTxts: {
    marginLeft: "70px",
    padding: "3px",
    margin: "0",
    marginLeft: "-90px",
    paddingBottom: "24px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-18px",
      lineHeight: "30px",
      paddingBottom: "24px",
      marginLeft: "auto",
    },
  },
  whatsappDisclaimer: {
    color: "#acacac",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "24px",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      paddingRight: "0",
    },
  },
  cimg: {
    padding: "0 15px",
  },

  toolPadding: {
    paddingBottom: "24px",
  },
  logoImage1: {
    display: "flex",
    columnGap: "52px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "contents",
      columnGap: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "contents",
      columnGap: "0px",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-around",
      columnGap: "0px",
    },
  },
  containerImage: {
    marginTop: "79px",
    [theme.breakpoints.down("md")]: {
      marginTop: "39px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "39px",
    },
  },
  whatsappTitleImageContainer: {
    position: "absolute",
    top: "18px",
    paddingLeft: "6rem",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "6rem",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  listTopPadding: {
    paddingTop: "32px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },
  gridContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  relativeContainerSide: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "50px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "50px",
      margin: "auto",
    },
  },
  cardindexBackground: {
    boxShadow: "none",
    borderRadius: "12px",
    border: "solid 1px #0893af",
  },
  bannerImageClass: {
    marginTop: "0px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
  boostTextwidth: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up(1120)]: {
      width: "1120px",
    },
  },
  listColumn: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexFlow: "column-reverse",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexFlow: "column-reverse",
    },
  },
  headingwidth: {
    maxWidth: "996px",
    paddingTop: "32px",
    paddingBottom: "18px",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  cardindexBackground: {
    boxShadow: "none",
    borderRadius: "12px",
    border: "solid 1px #0893af",
  },

  cardindexBackground1: {
    objectFit: "contain",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 2px #0379895e",
  },
  cardindexBackground2: {
    objectFit: "contain",
    borderRadius: "12px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 2px #00bb4487",
  },
  textImage: {
    marginBottom: "11px",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      textAlign: "left",
    },
  },
  cardindexBackground3: {
    objectFit: "contain",
    borderRadius: "12px",
    boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 2px #df9e037d",
  },
  whyText: {
    textAlignLast: "start",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-60px",
    },
  },
  smartCrmToolPadding: {
    textAlign: "center",
    position: `relative`,
    background: `#fff url(${firstImage}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
    marginTop: "-50px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      marginBottom: "-20px",
    },
  },
  smartCrmTool: {
    position: `relative`,
    background: `#fff url(${secondImage}) no-repeat center`,
    backgroundSize: "cover",
    width: "100%",
  },
  pcWebForm: {
    width: "100%",
    marginTop: "-7px",
    height: "496px",
    [theme.breakpoints.down("sm")]: {
      height: "529px",
    },
  },
  webformContainer: {
    height: "490px",
    [theme.breakpoints.down("sm")]: {
      height: "535px",
    },
  },
  demobtn: {
    paddingTop: "40px",
  },
  gap: {
    paddingTop: "70px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0px",
    },
  },
  whatsapptextImg1: {
    position: "absolute",
    right: "-19px",
    width: "360.7px",
    bottom: "-35px",
    [theme.breakpoints.down("md")]: {
      bottom: "-35px,",
      right: "0px",
      width: "100%",
      position: "absolute",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "-35px,",
      right: "0px",
      width: "100%",
      position: "absolute",
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      bottom: "-48px,",
      right: "-9px",
      width: "100%",
    },
  },
  nopadding: {
    display: "block",
    gap: "1rem",

    // marginRight: "-76px",
    [theme.breakpoints.up(1200)]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  overlapImage: {
    position: "relative",
    zIndex: "1000",
    width: "211.2px",
    height: "102.9px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      zIndex: "0",
      height: "80.9px",
    },
    [theme.breakpoints.down("md")]: {
      width: "171.2px",
      height: "95.9px",
    },
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      zIndex: "0",
      height: "82.9px",
    },
  },
  overlapBox: {
    justifyContent: "space-around",
    display: "flex",
    padding: "0 31px",
    [theme.breakpoints.down("sm")]: {
      display: "contents",
      padding: "0px",
    },
  },
  boostText: {
    paddingBottom: "21px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },
  overlapBackground: {
    borderRadius: "10px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#fff",
    zIndex: "1000",
    position: "relative",
    height: "145px",
    marginTop: "-53px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      marginTop: "30px",
      paddingBottom: "14px",
    },
  },
  overlapBackgrounds: {
    borderRadius: "10px",
    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
    backgroundColor: "#fff",
    zIndex: "1000",
    position: "relative",
    height: "145px",
    marginTop: "-53px",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      marginTop: "-30px",
      paddingBottom: "14px",
    },
  },
  listMargin: {
    marginLeft: "-17px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px",
    },
  },
  headingText: {
    fontSize: "40px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
    },
  },

  listImage: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
      textAlign: "center",
      flex: "0 0 calc(50% - 20px)",
    },
  },

  relativeContainer: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
    },
  },

  reactangelImageContainer1: {
    position: "absolute",
    right: "-18px",
    top: "99px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  relativeContainer2: {
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "100px",
      margin: "auto",
    },
  },
  rowImages: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "-200px",
    },
  },
  googlePlay: {
    width: "207px",
    height: "62px",
    objectFit: "contain",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "30px",
    },
  },
  qrScanner: {
    width: "158px",
    height: "158px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "60px",
      height: "60px",
      marginTop: "25px",
    },
  },
  footerRowImages: {
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-75px",
    },
  },
  footerGooglePlay: {
    width: "568px",
    height: "81px",
    objectFit: "contain",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "220px",
      height: "30px",
    },
  },
  footerQrScanner: {
    width: "158px",
    height: "158px",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      width: "60px",
      height: "60px",
      marginTop: "25px",
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 24,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginTop: "10px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  listItemText: {
    cursor: "pointer",
    "& *": {
      fontSize: 20,
      lineHeight: "30px",
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
    "& strong": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  listItem: {
    paddingLeft: "0px",
  },
  listWidth: {
    width: "50%",
  },
  list: {
    paddingLeft: "8px",
    alignItems: "flex-start",
    "&.selected ": {
      backgroundColor: "#e3f7f8",
      borderRadius: "4px",
      "@media(max-width:960px)": {
        backgroundColor: "transparent",
      },
    },
  },
  powerUpCardHead: {
    "@media(max-width:768px)": {
      fontSize: "17px",
    },
  },
  powerUpCardInner1: {
    backgroundColor: "#fff0f0",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "20px",
  },
  powerUpCardInner2: {
    backgroundColor: "#d4f9de",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "20px",
  },
  powerUpCardInner3: {
    backgroundColor: "#fef3be",
    padding: "14px 20px",
    minHeight: "150px",
    borderRadius: "20px",
  },
  mobilePad: {
    [theme.breakpoints.down("sm")]: {
      padding: "12px",
    },
  },
  boldText: {
    fontWeight: 600,
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  normalText: {
    fontSize: "20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  yellowImageLine: {
    [theme.breakpoints.down("sm")]: {
      transform: "rotate(90deg)",
      marginTop: "-80px",
      marginBottom: "-60px",
    },
  },
  ylwImageCont: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  yellowLineBig: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  stayResp: {
    [theme.breakpoints.up("sm")]: {
      fontSize: 36,
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: 34,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 40,
    },
  },
  rectcard: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "-50px",
    },
  },
  bestMobilehero : {
    objectFit : "contain",
    [theme.breakpoints.up("sm")]: {
      marginTop: "20px",
    },
  }
}));

const listFive = `Full-fledged mobile app
24/7 Local support
Free CRM app for Android and iOS
Built for SMEs`.split(`
`);

const CustomExperience =
  `Powerful omnichannel mobile app: Unify WhatsApp, Facebook Messenger, and more in omnichannel mobile inbox. Manage all your sales chats on the go without switching the apps.
  Access instant customer insights: Use the Pepper Cloud mobile app to quickly access customer details before meetings. Easily search for customer preferences, details, comments, and conversation history to prepare for impactful meetings that seal the deal.
  Real-time auto-sync: Update client information seamlessly between your phone and CRM, ensuring you stay informed on the go using the best mobile CRM system.
  Integrated AI chatbot: Reply instantly to customer queries, FAQs and more with AI chatbots, and set up automated chat flows to never miss sales opportunities.
  Provide 24/7 customer support: Respond to inquiries anytime, anywhere. Access group chats for comprehensive client support and effortlessly assign support tickets to your team on the go.`
    .split(`
  `);

const whatsapp = () => {
  const classes = useStyles();

  const width = useWidth();
  const [activeCalendar, setActiveCalendar] = React.useState(0);
  const salesCRMSolution = [
    {
      title: "What is mobile CRM? ",
      description: (
        <>
          A mobile CRM system is a software application designed specifically
          for smartphones and tablets. It allows sales teams and other team
          members to access and manage customer data, conversations, and tasks
          while on the go. Pepper Cloud is one of the best mobile CRM apps, and
          you can download the CRM app for both{" "}
          <a
            href="https://play.google.com/store/apps/details?id=com.peppercloud"
            style={{ color: "#198cb5" }}
          >
            Android{" "}
          </a>
          and{" "}
          <a
            href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415"
            style={{ color: "#198cb5" }}
          >
            iOS.
          </a>
        </>
      ),
    },
    {
      title: "What are the benefits of mobile CRM?",
      description: (
        <>
          The app provides a wide range of benefits, it allows you to:
          <ul>
            <li>Manage comprehensive sales conversations on your mobile.</li>
            <li>
              Instantly respond to client messages and incoming lead inquiries.
            </li>
            <li>Access all messaging apps through a centralised platform.</li>
            <li>Auto-sync conversations with CRM in real-time.</li>
            <li>
              Provide 24/7 customer support and assign tickets to your team on
              the go.
            </li>
            <li>
              Streamline workflow with features like auto-notifications, AI
              chatbots, and automated tasks.
            </li>
          </ul>
        </>
      ),
    },
    {
      title:
        "Do I need a Pepper Cloud CRM account to use the mobile CRM system?",
      description: (
        <>
          Yes, an active Pepper Cloud CRM account is required to access the
          mobile app. To create a Pepper Cloud account,{" "}
          <a
            href="https://peppercloud.com/contact"
            style={{ color: "#198cb5" }}
          >
            contact us.
          </a>
        </>
      ),
    },
    {
      title:
        "Which messaging apps does the Pepper Cloud mobile CRM integrate with?",
      description: (
        <>
          The mobile CRM app integrates with popular messaging channels like{" "}
          <a
            href="https://peppercloud.com/whatsapp-crm"
            style={{ color: "#198cb5" }}
          >
            Whatsapp,{" "}
          </a>
          <a
            href="https://blog.peppercloud.com/how-to-integrate-facebook-messenger-with-pepper-cloud-crm/"
            style={{ color: "#198cb5" }}
          >
            Facebook Messenger,{" "}
          </a>
          <a
            href="https://blog.peppercloud.com/integrating-telegram-with-crm/"
            style={{ color: "#198cb5" }}
          >
            Telegram,{" "}
          </a>
          <a
            href="https://blog.peppercloud.com/integrating-telegram-with-crm/"
            style={{ color: "#198cb5" }}
          >
            Instagram{" "}
          </a>
          and many others.
        </>
      ),
    },
    {
      title:
        "Can I efficiently capture leads and handle sales conversations directly within the mobile CRM system?",
      description: (
        <>
          Absolutely, the app offers a unified inbox for managing all your sales
          conversations and comes with lead capture features such as{" "}
          <a
            href="https://blog.peppercloud.com/track-click-to-chat-ad-leads-and-link-conversation-with-records/"
            style={{ color: "#198cb5" }}
          >
            Click-to-WhatsApp ads,{" "}
          </a>
          <a
            href="https://peppercloud.com/lead-capture-generation-webform"
            style={{ color: "#198cb5" }}
          >
            Webforms,{" "}
          </a>
          and more to streamline your sales process.
        </>
      ),
    },
    {
      title: "How can I setup Pepper Cloud mobile CRM system?",
      description: (
        <>
          To set up Pepper Cloud mobile CRM system, follow the steps below:{" "}
          <ul>
            <li>
              Ensure you have an active Pepper Cloud CRM account. In case you
              don’t have a Pepper Cloud CRM account,{" "}
              <a
                href="https://peppercloud.com/contact"
                style={{ color: "#198cb5" }}
              >
                contact us{" "}
              </a>
              to create one.
            </li>
            <li>
              Download the Pepper Cloud CRM mobile app from{" "}
              <a
                href="https://play.google.com/store/apps/details?id=com.peppercloud"
                style={{ color: "#198cb5" }}
              >
                Google Play Store{" "}
              </a>
              (Android) or{" "}
              <a
                href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415"
                style={{ color: "#198cb5" }}
              >
                App Store{" "}
              </a>
              (iOS).
            </li>
            <li>
              Sign in using your CRM credentials once the app is installed.
            </li>
            <li>
              Begin managing your CRM operations directly from your mobile
              device.
            </li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <SEO
        canonical="/best-mobile-crm-system-app-ios-android"
        description="Access your CRM data anywhere with the Pepper Cloud CRM Mobile App. Get mobile CRM system for both iOS & Android. Install it today from Apple's App Store or Google Play Store!"
        keywords="mobile crm systems,crm app,crm ios,free crm app for android,best mobile crm,benefits of mobile crm"
        pathname="/best-mobile-crm-system-app-ios-android"
        title="Best Mobile CRM system app for Android & iOS | Pepper Cloud"
      />

      <Box className={classes.singaporeSmeBanner} id="demo-section">
        <Container>
          <Grid alignItems="flex-start" container justify="center" mt={3}>
            <Grid item md={9} sm={12} className={classes.singaporeSmeBanner2}>
              <Box mt={8}>
                <HeaderTypography
                  className={classes.stayResp}
                  fontWeight={600}
                  lineHeight={1.5}
                  color="#000"
                  mb={4}
                  mt={3}
                  overrideClassName
                  component="h1"
                  textAlign="center"
                  maxWidth="760px"
                >
                  Stay responsive on-the-go with the <br /> best mobile CRM
                  system{" "}
                  {/* <span style={{ color: "#15a323" }}>WhatsApp CRM</span> */}
                </HeaderTypography>
                <ParagraphTypography
                  className={classes.smeBannerTxt}
                  color="#000"
                  font-family="SourceSansPro"
                  fontSize={18}
                  mb={0}
                  mt={0}
                  textAlign="center"
                  maxWidth="740px"
                  lineHeight={1.67}
                >
                  Discover the ease of managing sales and customer interactions
                  directly from your mobile device. With the free mobile CRM
                  system from Pepper Cloud, stay seamlessly connected and
                  productive whether you are in the office, on the road, or
                  working remotely. Available now for Android and iOS.
                </ParagraphTypography>
                <br />
                <Box
                  display={{ sm: "block", xs: "block" }[width] || "flex"}
                  className={classes.bannerImageClass}
                >
                  <Box
                    mt={0}
                    textAlign="center"
                    alignContent="center"
                    alignItems="center"
                    className={classes.rowImages}
                  >
                    <a
                      href="https://play.google.com/store/apps/details?id=com.peppercloud"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        alt="Google Play"
                        placeholder="blurred"
                        src="../images/v2/mobile-crm/Google-Play-img.webp"
                        className={classes.googlePlay}
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StaticImage
                        alt="App Store"
                        placeholder="blurred"
                        src="../images/v2/mobile-crm/Appstore.webp"
                        className={classes.googlePlay}
                      />
                    </a>
                  </Box>

                  <Box/>
                  <StaticImage
                  src="../images/v2/mobile-crm/crm-hero-image.webp"
                  placeholder="blurred"
                  alt="Best Mobile CRM System"
                  maxHeight = "550px"
                  maxWidth = "100%"
                  objectFit="contain"
                                    className={classes.bestMobilehero}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        component={Container}
        textAlign="center"
        mt={-13}
        zIndex={1000}
        position="relative"
        className={classes.rectcard}
      >
        <Box minHeight="260px">
          <Grid container spacing={4}>
            <Grid className={classes.powerUpCard} item md={4} sm={6}>
              <Box className={classes.powerUpCardInner1}>
                <HeaderTypography
                  className={classes.powerUpCardHead}
                  fontFamily="'SourceSansPro', sans-serif"
                  color="#000"
                  component="h3"
                  fontSize="22px"
                  fontWeight={600}
                  mb={1}
                  mt={0}
                  textAlign={"center"}
                >
                  Faster deal closures
                </HeaderTypography>
                <ParagraphTypography
                  color="#000"
                  fontSize="16px"
                  m={0}
                  textAlign="center"
                >
                  Master sales with mobile messaging, real-time data, and
                  instant lead response - all at your fingertips.
                </ParagraphTypography>
              </Box>
            </Grid>
            <Grid className={classes.powerUpCard} item md={4} sm={6}>
              <Box className={classes.powerUpCardInner2}>
                <HeaderTypography
                  className={classes.powerUpCardHead}
                  fontFamily="'SourceSansPro', sans-serif"
                  color="#000"
                  component="h3"
                  fontSize="22px"
                  fontWeight={600}
                  mb={1}
                  mt={0}
                  textAlign={"center"}
                >
                  Zero missed leads
                </HeaderTypography>
                <ParagraphTypography
                  color="#000"
                  fontSize="16px"
                  m={0}
                  textAlign="center"
                >
                  24/7 CRM access with instant mobile notification turns leads
                  into conversions.
                </ParagraphTypography>
              </Box>
            </Grid>
            <Grid className={classes.powerUpCard} item md={4} sm={6}>
              <Box className={classes.powerUpCardInner3}>
                <HeaderTypography
                  className={classes.powerUpCardHead}
                  fontFamily="'SourceSansPro', sans-serif"
                  color="#000"
                  component="h3"
                  fontSize="22px"
                  fontWeight={600}
                  mb={1}
                  mt={0}
                  textAlign={"center"}
                >
                  Streamlined team success
                </HeaderTypography>
                <ParagraphTypography
                  color="#000"
                  fontSize="16px"
                  m={0}
                  textAlign="center"
                >
                  Assign support tickets and manage team communication directly
                  from your phone.
                </ParagraphTypography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.smartCrmToolPadding}>
        <Box component={Container} textAlign="center">
          <HeaderTypography
            component="h2"
            fontSize={40}
            fontWeight={600}
            className={classes.headingwidth}
            color="#000"
            // maxWidth="800px"
          >
            Turn travel time into productive business hours with Pepper Cloud
            CRM mobile app
          </HeaderTypography>

          <Grid alignItems="center" container justify="center">
  <Grid item md={6} sm={12}>
    <Box
      display="flex"
      justifyContent={{ xs: "center", sm: "center", md: "flex-end" }}
    >
      <Box className={classes.mobilePad} maxWidth={620}>
        <List>
          {CustomExperience.map((each, index) => {
            const [boldText, ...normalTextArr] = each.split(":");
            const normalText = normalTextArr.join(":");
            return (
              <ListItem
                alignItems="center"
                className={classes.list}
                key={index}
              >
                <ListItemIcon>
                  <CheckIcon className={classes.listItemIcon} />
                </ListItemIcon>
                <ListItemText>
                  <span className={classes.boldText}>
                    {boldText}:
                  </span>
                  <span className={classes.normalText}>
                    {normalText}
                  </span>
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  </Grid>
  <Grid item md={6} sm={12}>
    <Box className={classes.buildExperiencesImg} textAlign="right">
      <StaticImage
        alt="Mobile CRM App"
        className={classes.img}
        height={"400px"}
        placeholder="blurred"
        src="../images/v2/mobile-crm/mobile-crm-responsive.webp"
      />
    </Box>
  </Grid>
</Grid>
        </Box>
      </Box>
      <br />
      <br />
      {/* Content */}
      <Box textAlign="center" className={classes.pepperCloudCRMHelpsContent}>
        <HeaderTypography
          component="h2"
          fontWeight={600}
          style={{ padding: "0 10px" }}
          className={classes.headingText}
          color="#000"
        >
          Navigate through business operations using your mobile device{" "}
        </HeaderTypography>
        <Box
          py={{ sm: 4, xs: 3 }}
          className={classes.pepperCloudCRMHelpsContent}
        >
          <Box
            py={{ sm: 4, xs: 3 }}
            className={classes.pepperCloudCRMHelpsContent}
          >
            <Container>
              <Box className={classes.pepperCloudCRMHelpsContentListBox}>
                <ul className={classes.pepperCloudCRMHelpsContentList}>
                  <li className={classes.pepperCloudCRMHelpsContentListItem}>
                    <Box
                      alignItems="center"
                      style={{ display: "flex" }}
                      className={classes.ylwImageCont}
                    >
                      <div className={classes.yellowLineBig}>
                        <div
                          className={
                            classes.pepperCloudCRMHelpsContentListItemSpan
                          }
                        >
                          34%
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          Increase in productivity from smartphone usage.
                        </div>
                      </div>
                      <div className={classes.yellowImageLine}>
                        <img
                          src={yellowLine}
                          style={{ marginLeft: "45px" }}
                          alt="vertical line"
                        />
                      </div>
                    </Box>
                  </li>
                  <li className={classes.pepperCloudCRMHelpsContentListItem}>
                    <Box
                      alignItems="center"
                      style={{ display: "flex" }}
                      className={classes.ylwImageCont}
                    >
                      <div className={classes.yellowLineBig}>
                        <div
                          className={
                            classes.pepperCloudCRMHelpsContentListItemSpan
                          }
                        >
                          75%
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          IT leaders deem mobile devices to be indispensable.
                        </div>
                      </div>
                      <div className={classes.yellowImageLine}>
                        <img
                          src={yellowLine}
                          style={{ marginLeft: "45px" }}
                          alt="vertical line"
                        />
                      </div>
                    </Box>
                  </li>
                  <li className={classes.pepperCloudCRMHelpsContentListItem}>
                    <Box
                      alignItems="center"
                      style={{ display: "flex" }}
                      className={classes.ylwImageCont}
                    >
                      <div className={classes.yellowLineBig}>
                        <div
                          className={
                            classes.pepperCloudCRMHelpsContentListItemSpan
                          }
                        >
                          80%
                        </div>
                        <div style={{ fontSize: "14px" }}>
                          Global workforce operates without a dedicated desk.
                        </div>
                      </div>
                      <div
                        className={classes.yellowImageLine}
                        style={{ visibility: "hidden" }}
                      >
                        <img
                          src={yellowLine}
                          style={{ marginLeft: "45px" }}
                          alt="vertical line"
                        />
                      </div>
                    </Box>
                  </li>
                </ul>
              </Box>
              <Box className={classes.whatsappDisclaimer}>
                *Mobile app statistics from multiple sources.
              </Box>
            </Container>
          </Box>
        </Box>
      </Box>

      <Box mb={8} className={classes.faqbackground}>
        <Box mt={0} mb={0}>
          <Divider light />
        </Box>
        <Container>
          <section>
            <HeaderTypography
              className={classes.pepperCloudCRMHelps}
              fontSize={40}
              fontWeight={600}
              my={4}
              textAlign="center"
              color="#000"
            >
              Frequently asked questions
            </HeaderTypography>
            <Card className={classes.faqCard}>
              <Box padding={3}>
                {salesCRMSolution.map((each, index) => (
                  <div key={each.title}>
                    <Box my={2}>
                      <Box
                        alignItems="center"
                        component="h3"
                        display="flex"
                        justifyContent="flex-start"
                        onClick={() =>
                          setActiveCalendar(
                            index === activeCalendar ? -1 : index
                          )
                        }
                      >
                        <IconButton
                          aria-label="Expand or Collapse"
                          className={classes.expendButton}
                          color="default"
                        >
                          {index !== activeCalendar ? (
                            <AddIcon fontSize="small" />
                          ) : (
                            <RemoveIcon fontSize="small" />
                          )}
                        </IconButton>
                        <Box
                          component="h3"
                          mt={0}
                          fontSize={18}
                          fontWeight="600"
                          m={0}
                          ml={3}
                        >
                          {each.title}
                        </Box>
                      </Box>
                      <Collapse in={activeCalendar === index}>
                        <ParagraphTypography
                          color="#2e3f4f"
                          fontSize={18}
                          mr={6}
                          mt={2}
                          ml={8.5}
                        >
                          {each.description}
                        </ParagraphTypography>
                      </Collapse>
                    </Box>
                    <Divider color="#707070" />
                  </div>
                ))}
              </Box>
            </Card>
          </section>
        </Container>
      </Box>

      {/* Book a free demo */}

      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Container>
          <Box alignItems="center" display="flex" justifyContent="center">
            <HeaderTypography
              color="common.white"
              fontSize={35}
              fontWeight={600}
              my={0}
              textAlign="center"
              component="h2"
              className={classes.boostText}
            >
              Get your pocket-sized sales powerhouse today!
            </HeaderTypography>
          </Box>
          <Grid item md={10} sm={12}>
            <Box className={classes.boostTextwidth}>
              <List className={classes.nopadding} pl={0}>
                {listFive.map((each) => (
                  <ListItem
                    alignItems="center"
                    className={classes.nopad}
                    key={each}
                  >
                    <ListItemIcon className={classes.tickImages}>
                      <Box alt="Check icon" component={"img"} src={tickImage} />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemTextWhite}>
                      {each}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>

              <Box
                mt={6}
                textAlign="center"
                alignContent="center"
                alignItems="center"
              >
                <Box className={classes.footerRowImages}>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.peppercloud"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      alt="Google Play"
                      placeholder="blurred"
                      src="../images/v2/mobile-crm/Google-Play-img.webp"
                      className={classes.googlePlay}
                    />
                  </a>
                  <a
                    href="https://apps.apple.com/in/app/pepper-cloud-mobile-crm/id6499458415"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      alt="App Store"
                      placeholder="blurred"
                      src="../images/v2/mobile-crm/Appstore.webp"
                      className={classes.googlePlay}
                    />
                  </a>

                  <StaticImage
                    alt="Pepper Cloud Mobile App QR Code"
                    placeholder="blurred"
                    src="../images/v2/mobile-crm/Qr-Scanner.png"
                    className={classes.footerQrScanner}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default whatsapp;
